@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer components {
  body {
    @apply bg-neutral-600 text-light-700 antialiased font-sans;
  }

  .btn-primary {
    @apply py-3 px-6 bg-gradient-to-r from-lime-500 to-teal-500 rounded-full text-neutral-600 text-button text-center inline-block;
  }

  .btn-secondary {
    @apply py-3 px-6 bg-neutral-500 rounded-full text-white text-button text-center inline-block;
  }

  .btn-outline {
    @apply py-2 px-4 text-light-500 border-2 border-light-700 rounded-full text-small text-center inline-block;
  }

  .btn-contrast {
    @apply py-3 px-6 bg-lime-600 rounded-full text-neutral-500 text-button text-center inline-block;
  }

  .bg-neon {
    @apply bg-gradient-to-r from-lime-500 to-teal-500;
  }

  .text-neon {
    @apply bg-gradient-to-r from-lime-500 to-teal-500 text-transparent bg-clip-text;
  }

  .table-default td,
  .table-default th {
    @apply py-6 px-4 text-p text-left;
  }

  .table-default th {
    @apply font-regular;
  }

  .article p  { @apply text-p mb-4; }
  .article strong  { @apply font-medium; }
  .article ul { @apply text-p mb-4; }
  .article p  { @apply mb-4; }
  .article h5 { @apply text-h5 text-light-100 mb-6 mt-8; }
  .article ol { @apply text-p pl-10 list-decimal; }
  .article ul { @apply text-p pl-10 list-disc; }
  .article ol ol, .article ul ul { @apply pt-2 pb-4; }
  .article ol li, .article ul li { @apply pb-2; }
}
